import { TranslateService } from '@ngx-translate/core';

export enum TipoDispositivo {
  MOBILE = 'MOBILE',
  BROWSER = 'BROWSER',
  DESKTOP = 'DESKTOP',
  TODOS = 'TODOS',
}

export function getTipoDispositivo(tipo: TipoDispositivo, translateService: TranslateService): string {
  switch (tipo) {
    case TipoDispositivo.MOBILE:
      return translateService.instant('tipoDispositivoEnum.mobile');
    case TipoDispositivo.BROWSER:
      return translateService.instant('tipoDispositivoEnum.browser');
    case TipoDispositivo.DESKTOP:
      return translateService.instant('tipoDispositivoEnum.desktop');
    case TipoDispositivo.TODOS:
      return translateService.instant('tipoDispositivoEnum.todos');
  }
}
